import React, { useCallback, useEffect, useState } from "react";
import { ICONS } from "../../../../assets/icons";
import { Popover } from "antd";
import { setDateCombine1 } from "../../../../reduxToolkit/CampaignSlice";
import {
  useAspDispatch,
  useAspSelector,
} from "../../../../test/jest-redux-hooks";
import moment from "moment";
import { useLocation } from "react-router-dom";
import {
  setCallEnabled,
  setDelayValue,
  setDelayValue1,
  setIndividualError,
  setSelectedDate,
  setSelectedTime,
  setSelectedTime1,
  setUpdatedInputValue,
} from "../../../../reduxToolkit/broadcastSlice";

import {
  getTimeString,
  formatTimeWithAmPm,
} from "../../../../helper/timeUtils";
import TimePickers from "./TimePickers";

const Individual = ({
  selectedIndexSegments,
  selectedIndex,
  response1,
  setResponse1,
  setSelectedId2,
  broadCasts,
  indexRem,
  contactsRemaining1,
  contactsRemaining2,
  setContactsRemaining1,
  setContactsRemaining2,
  totalContact1,
  totalContact2,
  setTotalContact1,
  setTotalContact2,
}) => {
  const [hasChange1, setHasChange1] = useState(false);
  const [hasChange2, setHasChange2] = useState(false);
  const [hours, setHours] = useState("12");
  const [minutes, setMinutes] = useState("00");
  const [periods, setPeriod] = useState("AM");
  const [open, setOpen] = useState(false);
  const [rem, setRem] = useState({});
  const [isDatePickerOpen1, setIsDatePickerOpen] = useState({});

  const dispatch = useAspDispatch();
  const location = useLocation();

  useEffect(() => {
    const valids = response1?.map((data) => data?.valid_count);

    if (valids) {
      if (valids[0] !== undefined) {
        setTotalContact1(valids[0]);
      }

      if (valids[1] !== undefined) {
        setTotalContact2(valids[1]);
      }
    }
  }, []);

  const { dateCombine } = useAspSelector((state) => state?.Campaign);

  const { individualError, delayValues1, selectedTime1, callEnabled } =
    useAspSelector((state) => state?.broadcast);

  const handleTimePickerOpen1 = () => {
    setOpen(!open);
  };

  const handleTimePickerOpen = (dataId, segmentId) => {
    setIsDatePickerOpen((prev) => ({
      ...prev,
      [`${dataId}-${segmentId}`]: !prev[`${dataId}-${segmentId}`],
    }));
  };

  const handleOkClick = (dataId, segmentId) => {
    setOpen(false);
    setIsDatePickerOpen((prev) => ({
      ...prev,
      [`${dataId}-${segmentId}`]: false,
    }));
  };

  const handleTimeChange = (id, segmentId) => {
    const newSelectedTime = getTimeString(hours, minutes, periods);

    if (!newSelectedTime) {
      return;
    }

    let formattedTime;
    if (hours === 12) {
      formattedTime = `${hours}:${minutes
        .toString()
        .padStart(2, "0")} ${periods}`;
    } else {
      formattedTime = formatTimeWithAmPm(newSelectedTime);
    }

    const formattedDateCombine = moment(dateCombine).format("YYYY-MM-DD");
    const combined = `${formattedDateCombine} ${formattedTime}`;

    const dateCombineDateTime = moment(dateCombine).format("hh:mm A");

    if (
      formattedDateCombine === moment(dateCombine).format("YYYY-MM-DD") &&
      moment(formattedTime, "hh:mm A").isSameOrBefore(
        moment(dateCombineDateTime, "hh:mm A")
      )
    ) {
      return;
    }

    const segmentsToUpdate =
      response1.find((res) => res.id === id)?.segments || [];
    const prevSegmentIndex =
      segmentsToUpdate.findIndex((segment) => segment.id === segmentId) - 1;

    if (prevSegmentIndex >= 0) {
      const prevSegment = segmentsToUpdate[prevSegmentIndex];
      const prevSegmentTime = prevSegment.schedule_on;
      const timeDiff = moment(combined).diff(moment(prevSegmentTime), "hours");
      if (timeDiff < 1) {
        return;
      }
    }
    const updatedResponse = response1.map((res) => {
      if (res?.id === id) {
        const updatedSegments = res.segments.map((segment1) => {
          if (segment1.id === segmentId) {
            if (selectedIndex === "send_later") {
              return { ...segment1, schedule_on: combined };
            }
          }
          if (segment1.id > segmentId) {
            if (selectedIndex === "send_later") {
              return { ...segment1, schedule_on: "" };
            }
          }
          return segment1;
        });
        return { ...res, segments: updatedSegments };
      } else if (res?.id === 2) {
        const updatedSegments = res.segments.map((segment1) => {
          if (selectedIndex === "send_later") {
            return { ...segment1, schedule_on: "" };
          }
          return segment1;
        });
        return { ...res, segments: updatedSegments };
      }
      return res;
    });

    dispatch(
      setSelectedTime({
        id: id,
        segmentId: segmentId,
        time: formattedTime,
      })
    );

    if (id === 1) {
      dispatch(
        setSelectedTime1({
          id: id,
          time: "",
        })
      );
    }
    setResponse1(updatedResponse);
  };

  const formattedDateCombine = moment(dateCombine).format("YYYY-MM-DD");

  const handleTimeChange1 = (id) => {
    const newSelectedTime = getTimeString(hours, minutes, periods);

    if (!newSelectedTime) {
      return;
    }

    const formattedTime1 = formatTimeWithAmPm(newSelectedTime);
    const selectedDateForSegment1 = formattedDateCombine;
    const combined1 = `${selectedDateForSegment1} ${formattedTime1}`;

    const previousTime = response1
      .find((res) => res?.id === id)
      ?.segments.find((segment1) => segment1.id === id)?.schedule_on;

    const previousTime1 = response1
      .find((res) => res?.id === id)
      ?.segments.find((segment1) => segment1.id === id + 1)?.schedule_on;

    const l = response1?.map((res) => res?.segments);

    const selectedPreviousTime =
      l?.[0]?.length > 1 ? previousTime1 : previousTime;

    if (selectedPreviousTime) {
      const previousDateTime = new Date(selectedPreviousTime);
      const newDateTime = new Date(combined1);
      const timeDifference = newDateTime - previousDateTime;

      const oneHourInMillis = 60 * 60 * 1000;
      if (timeDifference < oneHourInMillis) {
        return;
      }
    }

    const updatedResponse = response1.map((res) => {
      if (res?.id !== id) {
        return {
          ...res,
          segments: res.segments.map((segment) => {
            if (selectedIndex === "send_later" && segment.id === 1) {
              return {
                ...segment,
                schedule_on: combined1,
              };
            } else
              return {
                ...segment,
                schedule_on: "",
              };
          }),
        };
      }
      return res;
    });

    dispatch(
      setSelectedTime1({
        id: id,
        time: formattedTime1,
      })
    );

    if (response1?.[1]?.segments?.[1]?.id === 2) {
      dispatch(
        setSelectedTime({
          id: 2,
          segmentId: 2,
          time: "",
        })
      );
    }

    dispatch(setDateCombine1(combined1));
    setResponse1(updatedResponse);
  };

  const updateMergeFiles = (id, index) => {
    let lastSegmentRemaining1, lastSegmentRemaining2;
    if (
      selectedIndexSegments === "individual" &&
      selectedIndexSegments !== "merge"
    ) {
      if (index === 0) {
        lastSegmentRemaining1 = contactsRemaining1;
      } else if (index === 1) {
        lastSegmentRemaining2 = contactsRemaining2;
      }

      const res = response1?.map((res) => {
        if (res?.id === id) {
          const newId = res.segments.length + 1;
          let newData;

          if (selectedIndex === "send_now") {
            newData = {
              id: newId,
              start: 0,
              end: 0,
              delay: 0,
              flag: false,
            };
          } else {
            newData = {
              id: newId,
              start: 0,
              end: 0,
              schedule_on: "",
              flag: false,
            };
          }

          const updatedSegments = res.segments.map((segment1) => ({
            ...segment1,
            flag: true,
          }));

          return {
            ...res,
            segments: [...updatedSegments, newData],
          };
        } else {
          return res;
        }
      });

      if (
        selectedIndexSegments === "individual" &&
        selectedIndexSegments !== "merge"
      ) {
        setResponse1(res);
      }
      if (index === 0) {
        setContactsRemaining1(lastSegmentRemaining1);
      } else if (index === 1) {
        setContactsRemaining2(lastSegmentRemaining2);
      }
    }
    if (id === 1) {
      dispatch(
        setSelectedTime1({
          id: id,
          time: "",
        })
      );
    }
    dispatch(setUpdatedInputValue({}));
    if (response1?.length > 1) {
      dispatch(setDelayValue({}));
      dispatch(setUpdatedInputValue({}));
    } else if (response1?.length === 2) {
      dispatch(setDelayValue({}));
      dispatch(setUpdatedInputValue({}));
    } else {
      dispatch(setDelayValue({}));
      dispatch(setUpdatedInputValue({}));
    }
  };

  const handleChange = (event, segmentId, id, newValue = null) => {
    setRem((prev) => ({
      ...prev,
      [`${segmentId}-${id}`]: false,
    }));
    dispatch(setCallEnabled(false));

    const inputValue = newValue !== null ? newValue : event.target.value;
    dispatch(setUpdatedInputValue(inputValue));

    let newCount = 0;
    const newRes = response1?.map((r) => {
      if (id === r.id) {
        const updatedSegments = r.segments?.map((s) => {
          const startValue =
            s.id > 1 ? r.segments.find((seg) => seg.id === s.id - 1)?.end : 0;
          if (s.id === segmentId) {
            const updatedEnd = Math.max(0, +inputValue);
            newCount += updatedEnd;
            return {
              ...s,
              start: startValue,
              end: updatedEnd,
            };
          }
          newCount += s.end;
          return s;
        });
        return { ...r, segments: updatedSegments };
      }
      return r;
    });

    let updatedContactsRemaining;
    let totalContact;
    if (id === 1) {
      updatedContactsRemaining = Math.max(0, totalContact1 - newCount);
      setContactsRemaining1(updatedContactsRemaining);
      totalContact = totalContact1;
    } else if (id === 2) {
      updatedContactsRemaining = Math.max(0, totalContact2 - newCount);
      setContactsRemaining2(updatedContactsRemaining);
      totalContact = totalContact2;
    }

    dispatch(
      setUpdatedInputValue({
        id: id,
        segmentId: segmentId,
        inputValue: inputValue,
      })
    );

    let errors = { ...individualError };
    if (newCount > totalContact) {
      const exceededBy = newCount - totalContact;

      errors[id] = `Contact count exceeded by ${exceededBy}`;
    } else {
      delete errors[id];
    }

    dispatch(setIndividualError(errors));
    setResponse1(newRes);
  };

  const addBalanceContact = (segmentId, id) => {
    const newRes = response1?.map((r) => {
      if (id === r.id) {
        const updatedSegments = r.segments?.map((s) => {
          if (s.id === segmentId) {
            if (id === 1) {
              return {
                ...s,
                end: s.end + contactsRemaining1,
              };
            } else if (id === 2) {
              return {
                ...s,
                end: s.end + contactsRemaining2,
              };
            }
          }
          return s;
        });
        return { ...r, segments: updatedSegments };
      }
      return r;
    });

    setResponse1(newRes);

    setRem((prev) => ({
      ...prev,
      [`${segmentId}-${id}`]: true,
    }));

    if (id === 1) {
      setContactsRemaining1(0);
    } else if (id === 2) {
      setContactsRemaining2(0);
    }
  };

  function handleDelayChange(newDelay, segmentId, id) {
    let delayValue;

    if (newDelay !== "") {
      delayValue = parseInt(newDelay, 10);
      if (isNaN(delayValue) || delayValue < 0) {
        delayValue = "";
      }
    } else {
      delayValue = "";
    }

    // dispatch(setDelayValue(delayValue));

    const updatedDelay = response1?.map((res) => {
      if (res?.id === segmentId) {
        const updatedSegmentSettings = res?.segments?.map((segment1) => {
          if (segment1?.id === id) {
            if (selectedIndex === "send_now") {
              return { ...segment1, delay: delayValue };
            }
          }
          return segment1;
        });
        return {
          ...res,
          segments: updatedSegmentSettings,
        };
      }
      return res;
    });

    setResponse1(updatedDelay);
  }

  function handleDelayChange1(newDelay, segmentId, id) {
    let delayValue;

    if (newDelay !== "") {
      delayValue = parseInt(newDelay, 10);
      if (isNaN(delayValue) || delayValue < 0) {
        delayValue = "";
      }
    } else {
      delayValue = "";
    }

    dispatch(setDelayValue1(delayValue));

    const updatedDelay = response1.map((res) => {
      if (res?.id === 2) {
        return {
          ...res,
          segments: res.segments.map((segment) => {
            if (selectedIndex === "send_now" && segment.id === segmentId) {
              return {
                ...segment,
                delay: delayValue,
              };
            }
            return segment;
          }),
        };
      }
      return res;
    });

    setResponse1(updatedDelay);
  }

  const handleIncrementDelay = (segmentId, id) => {
    const increment = response1?.map((res) => {
      if (res.id === id) {
        const updateIncrement = res?.segments?.map((segment1) => {
          if (segment1?.id === segmentId) {
            if (segment1.delay < 20) {
              return { ...segment1, delay: segment1.delay + 1 };
            }
          }
          return segment1;
        });
        return {
          ...res,
          segments: updateIncrement,
        };
      } else {
        return res;
      }
    });
    const updatedSegment = increment
      ?.find((res) => res.id === id)
      ?.segments.find((segment) => segment.id === segmentId);

    const updatedDelayValue = updatedSegment?.delay;

    if (updatedDelayValue !== undefined) {
      dispatch(setDelayValue(updatedDelayValue));
    }
    setResponse1(increment);
  };

  const handleDecrementDelay = (segmentId, id) => {
    const increment = response1?.map((res) => {
      if (res.id === id) {
        const updateIncrement = res?.segments?.map((segment1) => {
          if (segment1?.id === segmentId) {
            if (segment1.delay > 5) {
              return { ...segment1, delay: segment1.delay - 1 };
            }
          }
          return segment1;
        });
        return {
          ...res,
          segments: updateIncrement,
        };
      } else {
        return res;
      }
    });
    const updatedSegment = increment
      ?.find((res) => res.id === id)
      ?.segments.find((segment) => segment.id === segmentId);

    const updatedDelayValue = updatedSegment?.delay;

    if (updatedDelayValue !== undefined) {
      dispatch(setDelayValue(updatedDelayValue));
    }
    setResponse1(increment);
  };

  const handleDeleteClick = (segmentIdToDelete, dataId, event) => {
    setRem(false);
    const inputValue = event.target.value;

    let deletecontacts = 0;

    const updatedResponse = response1?.map((data1) => {
      if (data1.id === dataId) {
        const deletedSegmentIndex = data1?.segments?.findIndex(
          (segment) => segment.id === segmentIdToDelete
        );

        if (deletedSegmentIndex !== -1) {
          const deletedSegment = data1.segments[deletedSegmentIndex];
          let remainingContacts = deletedSegment.end;

          if (inputValue === "") {
            remainingContacts = "";
            setSelectedId2(false);
          }

          deletecontacts =
            dataId === 1
              ? remainingContacts + contactsRemaining1
              : remainingContacts + contactsRemaining2;

          // Create a shallow copy of the segments array
          const updatedSegments = [...data1.segments];

          // Remove the segment from the copied array
          updatedSegments.splice(deletedSegmentIndex, 1);

          if (dataId === 1) {
            setContactsRemaining1(deletecontacts);
            setHasChange1(true);
          } else if (dataId === 2) {
            setContactsRemaining2(deletecontacts);
            setHasChange2(true);
          }

          if (deletedSegmentIndex === updatedSegments.length) {
            if (deletedSegmentIndex > 0) {
              const previousSegment = {
                ...updatedSegments[deletedSegmentIndex - 1],
                flag: false,
              };

              updatedSegments[deletedSegmentIndex - 1] = previousSegment;
            }
          }
          return {
            ...data1,
            segments: updatedSegments,
          };
        }
      }
      return data1;
    });

    dispatch(setSelectedDate({ type: "clear" }));
    dispatch(setSelectedTime({ type: "clear" }));
    setResponse1(updatedResponse);
  };

  //  Next-button ---------------------------------------

  const checkToAllowNext = useCallback(() => {
    let handleInput = [];
    let handleDelay = [];
    let handleDate = [];
    let handleTime = [];

    if (selectedIndexSegments === "individual") {
      response1?.forEach((file) => {
        file?.segments?.forEach((s) => {
          if (s?.end === 0) handleInput?.push(s);
          if (selectedIndex === "send_now") {
            if (s?.delay < 5) handleDelay?.push(s);
          } else {
            if (!s?.schedule_on?.split(" ")?.includes("")) {
              const scheduleOn = s?.schedule_on?.split(" ");
              const date = scheduleOn?.[0];
              const time = scheduleOn?.[1];
              if (date && time) {
                handleDate.push(date);
                handleTime.push(time);
              }
            } else {
              handleDate.push("");
              handleTime.push("");
            }
          }
        });
      });

      const noInputErrors = handleInput.length === 0;
      const noDelayErrors =
        selectedIndex === "send_now" ? handleDelay.length <= 1 : true;
      const noScheduleErrors =
        selectedIndex === "send_later"
          ? !handleDate.includes("") && !handleTime.includes("")
          : true;

      // Check if there are any errors in individualError
      const noIndividualErrors = Object.keys(individualError).length === 0;

      const canProceed =
        response1?.segments?.length < 0
          ? false
          : response1?.[0]?.segments?.length > 0 &&
            noInputErrors &&
            noDelayErrors &&
            noScheduleErrors &&
            noIndividualErrors;

      setSelectedId2(canProceed);
      return canProceed;
    }

    return false;
  }, [response1, selectedIndexSegments, individualError, selectedIndex]);

  checkToAllowNext();

  // Edit flow ---------------------------------------

  const call = useCallback(() => {
    if (broadCasts?.settings?.segment_settings) {
      if (!indexRem && broadCasts && location?.state?.item) {
        const editContacts1 =
          broadCasts?.settings?.contactsRemaining1Individual;
        const editContacts2 = broadCasts?.settings?.contactsRemaining2;
        setContactsRemaining1(editContacts1);
        setContactsRemaining2(editContacts2);
      }
    }
  }, [location?.state?.item]);

  useEffect(() => {
    if (callEnabled) {
      call();
    }

    if (location?.state?.item && broadCasts) {
      const delayValuess = response1?.[1]?.segments?.[0]?.delay;
      dispatch(setDelayValue1(delayValuess));
    }
  }, [call, callEnabled, location?.state]);

  return (
    <>
      {selectedIndexSegments === "individual" &&
        selectedIndexSegments !== "merge" && (
          <>
            {response1?.map((data, index) => {
              const segments = data?.segments || [];
              const error = individualError[data.id];
              const isFileValid = data?.segments?.every((segment) => {
                const { end, schedule_on } = segment;
                return end > 0 && schedule_on && schedule_on.trim() !== "";
              });

              return (
                <div
                  className="SendSetting-container flex-column"
                  key={data?.id}
                >
                  <div className="flex-row align-center w-100 ">
                    <div className="flex-row align-center">
                      <img
                        src={ICONS?.BroadcastSegmentIcon}
                        alt=""
                        style={{ width: "25px", height: "25px" }}
                      />
                      <span className="pl-10">Contact list - {index + 1}</span>
                    </div>

                    {error && (
                      <div
                        style={{ color: "red" }}
                        className="error-message flex-row align-center pl-10"
                      >
                        <img src={ICONS?.alertIconError} />
                        <p className="pl-2">{error}</p>
                      </div>
                    )}
                  </div>

                  <div className="flex-column resp">
                    {segments?.map((segment, index) => {
                      return (
                        <div className="pl-20" key={segment?.id}>
                          {segment?.id > 1 && (
                            <>
                              {selectedIndex === "send_now" ? (
                                <div className="flex-column">
                                  <div className="flex-row">
                                    <div className=" Delay-container flex-row align-center pl-5 ">
                                      <div className="flex-column align-center">
                                        <img
                                          src={ICONS?.delayIcon}
                                          alt="delay"
                                          className="delay-image"
                                        />
                                        <div className="line1" />
                                      </div>

                                      <p>Delay</p>
                                    </div>

                                    <div
                                      className="Delay-wrapper w-100 flex-row align-center  pl-11 gap-5"
                                      style={{ paddingLeft: "8%" }}
                                    >
                                      <span className="mt-1">Wait for</span>
                                      <div
                                        className="flex-row align-center flex-end"
                                        style={{
                                          border: "1px solid var(--font-50)",
                                          height: "4.5vh",
                                          padding: "10px",
                                        }}
                                      >
                                        <div className="flex-row flex-end align-center space-between">
                                          <input
                                            value={segment.delay}
                                            onChange={(e) => {
                                              if (e.target.value <= 20) {
                                                handleDelayChange(
                                                  e.target.value,
                                                  data?.id,
                                                  segment?.id
                                                );
                                              }
                                            }}
                                            onInput={(event) => {
                                              event.target.value =
                                                event.target.value.replace(
                                                  /[^0-9]/g,
                                                  ""
                                                );
                                            }}
                                            min="0"
                                            className="no-spinner"
                                            data-testid="Delay-button"
                                          />

                                          <div className="flex-column">
                                            <img
                                              src={
                                                ICONS?.BroadcastChevronUpIcon
                                              }
                                              alt="upIcon"
                                              className="upIcon pointer"
                                              onClick={() =>
                                                handleIncrementDelay(
                                                  segment.id,
                                                  data.id
                                                )
                                              }
                                            />
                                            <img
                                              src={
                                                ICONS?.BroadcastChevronDownIcon
                                              }
                                              alt="downIcon"
                                              className="downIcon pointer"
                                              onClick={() =>
                                                handleDecrementDelay(
                                                  segment.id,
                                                  data.id
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div
                                        style={{
                                          border: "1px solid var(--border-50)",
                                          padding: "10px 30px 0px 5px ",
                                          fontSize: "0.75vw",
                                          fontWeight: "500",
                                          color: "var(--font-600)",
                                          width: "8vw",
                                          height: "4.5vh",
                                        }}
                                      >
                                        <span>Minutes</span>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="flex-row align-center pl-20">
                                    <img
                                      src={ICONS?.MaximumIcon}
                                      alt=""
                                      className="pl-10"
                                    />

                                    <span className="delay-minutes pl-5">
                                      Delay should be greater than 5 minutes and
                                      less than 20 minutes
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <div className="Calendar-container flex-column w-100 space-between">
                                  <div className="aaaa flex-row align-center  ">
                                    <div className="flex-column align-center">
                                      <img
                                        src={ICONS?.delayIcon}
                                        alt="delay"
                                        className="delay-image"
                                      />
                                      <div className="line1" />
                                    </div>

                                    <span className="send_later pl-10">
                                      Scheduled on
                                    </span>
                                    <div className="ml-20">
                                      <div className="flex-row align-center">
                                        <input
                                          placeholder={"DD/MM/YY"}
                                          iconPrefix={
                                            ICONS?.BroadcastCalendarIcon
                                          }
                                          className="date-time"
                                          value={formattedDateCombine}
                                          readOnly={true}
                                        />
                                      </div>
                                    </div>
                                    <div className="broadcast-calendar flex-row align-center">
                                      <div className="At p-5">@</div>
                                      <Popover
                                        overlayClassName="time-popover"
                                        content={
                                          <TimePickers
                                            hours={hours}
                                            minutes={minutes}
                                            periods={periods}
                                            setHours={setHours}
                                            setMinutes={setMinutes}
                                            setPeriod={setPeriod}
                                            onClick={
                                              formattedDateCombine
                                                ? () =>
                                                    handleTimeChange(
                                                      data?.id,
                                                      segment?.id
                                                    )
                                                : (e) => e.preventDefault()
                                            }
                                            onOkClick={() =>
                                              handleOkClick(
                                                data?.id,
                                                segment?.id
                                              )
                                            }
                                          />
                                        }
                                        trigger="click"
                                        arrow={false}
                                        open={
                                          isDatePickerOpen1[
                                            `${data.id}-${segment.id}`
                                          ] || false
                                        }
                                        onOpenChange={() =>
                                          handleTimePickerOpen(
                                            data?.id,
                                            segment?.id
                                          )
                                        }
                                      >
                                        <div className="flex-row align-center pointer ml-10">
                                          <input
                                            placeholder={"00.00 AM"}
                                            iconPrefix={
                                              ICONS?.BroadcastTimerIcon
                                            }
                                            className="date-time"
                                            value={segment?.schedule_on
                                              ?.split(" ")
                                              ?.slice(1)
                                              ?.join(" ")}
                                          />
                                        </div>
                                      </Popover>
                                    </div>
                                  </div>
                                  <div className="flex-row align-center pl-20 pb-3">
                                    <img src={ICONS?.MaximumIcon} alt="" />

                                    <span className="delay-minutes pl-5">
                                      Delay should be greater than one hour
                                    </span>
                                  </div>
                                </div>
                              )}
                            </>
                          )}

                          {selectedIndexSegments === "individual" &&
                            selectedIndexSegments !== "merge" && (
                              <>
                                <div className="flex-row w-100 ">
                                  <div
                                    className="  flex-row align-center"
                                    style={{ width: "18%" }}
                                  >
                                    <div className="p-line flex-column  align-center justify-center h-60">
                                      <img
                                        src={ICONS?.BroadcastNewSegmentIcon}
                                        alt=""
                                        className="delay-image"
                                      />
                                      {index < data?.segments.length - 1 && (
                                        <div className="line" />
                                      )}
                                    </div>
                                    <p className="segment-name pl-10">
                                      Segment: {index + 1}
                                    </p>
                                  </div>
                                  <div className="Delay-wrapper w-100  flex-row align-center  ">
                                    <div className="segment-container flex-row align-center w-100 space-between pt-20">
                                      <span className="detail">
                                        Contact Count
                                      </span>

                                      <div className="increment w-100 flex-row align-center">
                                        <div
                                          key={segment.id}
                                          style={{
                                            border: "1px solid var(--font-50)",
                                            padding: "10px",
                                          }}
                                        >
                                          <div
                                            className="w-100 space-between flex-row align-center"
                                            style={{
                                              fontSize: "0.75vw",
                                              fontWeight: "600",
                                              color: "var(--textBlack)",
                                            }}
                                          >
                                            <input
                                              placeholder="Should be greater than 1"
                                              value={segment?.end}
                                              onChange={(event) =>
                                                handleChange(
                                                  event,
                                                  segment.id,
                                                  data.id
                                                )
                                              }
                                              onInput={(event) => {
                                                event.target.value =
                                                  event.target.value.replace(
                                                    /[^0-9]/g,
                                                    ""
                                                  );
                                              }}
                                            />
                                          </div>
                                        </div>

                                        <p className="contacts-details">
                                          <span>
                                            {segment.flag !== true &&
                                            !hasChange1 &&
                                            data?.id === 1
                                              ? contactsRemaining1
                                              : segment.flag !== true &&
                                                !hasChange2 &&
                                                data?.id === 2
                                              ? contactsRemaining2
                                              : segment.flag !== true &&
                                                hasChange1 &&
                                                data?.id === 1
                                              ? contactsRemaining1
                                              : segment.flag !== true &&
                                                hasChange2 &&
                                                data?.id === 2
                                              ? contactsRemaining2
                                              : 0}
                                          </span>
                                          Contact remaining
                                        </p>
                                      </div>
                                      {index === segments.length - 1 && (
                                        <div
                                          onClick={() => {
                                            addBalanceContact(
                                              segment?.id,
                                              data?.id
                                            );
                                          }}
                                          data-testid="Add-balance"
                                        >
                                          <div>
                                            <label className="checkBoxContainer">
                                              <input
                                                type="checkbox"
                                                name="checkbox"
                                                checked={
                                                  rem[
                                                    `${segment.id}-${data.id}`
                                                  ] || false
                                                }
                                              />
                                            </label>
                                            <div className="setting-setting flex-column">
                                              <span>{data?.name}</span>
                                              <p>{data?.content}</p>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>

                                    {index === segments.length - 1 &&
                                      index !== 0 && (
                                        <img
                                          src={ICONS?.deleteIcon}
                                          alt="delete"
                                          className="Delay-image pointer"
                                          onClick={(e) =>
                                            handleDeleteClick(
                                              segment?.id,
                                              data.id,
                                              e
                                            )
                                          }
                                          onMouseOver={(e) =>
                                            (e.currentTarget.src =
                                              ICONS?.BroadcastRedDeleteIcon)
                                          }
                                          onMouseOut={(e) =>
                                            (e.currentTarget.src =
                                              ICONS?.deleteIcon)
                                          }
                                        />
                                      )}
                                  </div>
                                </div>

                                {individualError[
                                  `${data?.id}-${segment?.id}`
                                ] && (
                                  <div
                                    style={{ color: "red" }}
                                    className="error-message flex-row align-center"
                                  >
                                    <img src={ICONS?.alertIconError} />
                                    <p className="pl-2">
                                      Audience count should be less than
                                      remaining value
                                    </p>
                                  </div>
                                )}
                              </>
                            )}
                        </div>
                      );
                    })}

                    {data?.segments?.length < 5 &&
                      ((data?.id === 1 && contactsRemaining1 !== 0) ||
                        (data?.id === 2 && contactsRemaining2 !== 0)) && (
                        <div
                          className={`flex-row   align-center ml-20 mt-15 ${
                            isFileValid && selectedIndex === "send_later"
                              ? "cursor-pointer"
                              : selectedIndex === "send_now"
                              ? "cursor-pointer"
                              : "cursor-not-allowed"
                          }`}
                        >
                          <img
                            src={ICONS?.AudienceRoundIcon}
                            alt="BroadcastAddIcon"
                            className="delay-image"
                            onClick={
                              isFileValid && selectedIndex === "send_later"
                                ? () => updateMergeFiles(data?.id)
                                : selectedIndex === "send_now"
                                ? () => updateMergeFiles(data?.id)
                                : null
                            }
                          />
                          <p
                            className="Add-task"
                            onClick={
                              isFileValid && selectedIndex === "send_later"
                                ? () => updateMergeFiles(data?.id)
                                : selectedIndex === "send_now"
                                ? () => updateMergeFiles(data?.id)
                                : null
                            }
                          >
                            Add Segment
                          </p>
                        </div>
                      )}

                    <div className="merge-container flex-row mb-10 mt-10">
                      <img src={ICONS?.MaximumIcon} alt="" />
                      <p>Maximum of 5 segments can be added to each part</p>
                    </div>
                  </div>

                  {data?.id === 1 &&
                  response1?.length > 1 &&
                  selectedIndex === "send_later" ? (
                    <div className="Calendar-container flex-row w-100 space-between">
                      <div className="aaaa flex-row align-center  ">
                        <img
                          src={ICONS?.BroadcastDelayIcon}
                          alt="delay"
                          className="delay-image"
                        />
                        <span className="send_later pl-10">Scheduled on</span>
                        <div className="ml-10">
                          <div className="flex-row align-center">
                            <input
                              placeholder={"DD/MM/YY"}
                              iconPrefix={ICONS?.BroadcastCalendarIcon}
                              value={formattedDateCombine}
                              readOnly={true}
                              className="date-time"
                            />
                          </div>
                        </div>
                        <div className="broadcast-calendar flex-row align-center">
                          <div className="At p-5">@</div>
                          <Popover
                            overlayClassName="time-popover"
                            content={
                              <TimePickers
                                hours={hours}
                                minutes={minutes}
                                periods={periods}
                                setHours={setHours}
                                setMinutes={setMinutes}
                                setPeriod={setPeriod}
                                onClick={() => handleTimeChange1(data?.id)}
                                onOkClick={() => handleOkClick(data?.id)}
                              />
                            }
                            trigger="click"
                            arrow={false}
                            open={open}
                            onOpenChange={() => handleTimePickerOpen1(data?.id)}
                          >
                            <div className="flex-row align-center pointer ml-10">
                              <input
                                placeholder={"00.00 AM"}
                                iconPrefix={ICONS?.BroadcastTimerIcon}
                                className="date-time"
                                value={selectedTime1[`${data.id}`]}
                                readOnly
                              />
                            </div>
                          </Popover>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {data?.id === 1 && response1?.length > 1 && (
                        <div className="flex-column">
                          <div className="flex-row">
                            <div className=" Delay-container flex-row align-center pl-5 ">
                              <img
                                src={ICONS?.BroadcastDelayIcon}
                                alt="delay"
                                className="delay-image"
                              />
                              <p>Delay</p>
                            </div>

                            <div
                              className="Delay-wrapper w-100 flex-row align-center  pl-11 gap-5"
                              style={{ paddingLeft: "8%" }}
                            >
                              <span className="mt-1">Wait for</span>
                              <div
                                className="flex-row align-center flex-end"
                                style={{
                                  border: "1px solid var(--font-50)",
                                  height: "4.5vh",
                                  padding: "10px",
                                }}
                              >
                                <div className="flex-row flex-end align-center space-between">
                                  <input
                                    value={delayValues1}
                                    onChange={(e) => {
                                      if (e.target.value <= 20) {
                                        handleDelayChange1(
                                          e.target.value,
                                          data?.id
                                        );
                                      }
                                    }}
                                    onInput={(event) => {
                                      event.target.value =
                                        event.target.value.replace(
                                          /[^0-9]/g,
                                          ""
                                        );
                                    }}
                                    min="0"
                                    className="no-spinner pointer"
                                    data-testid="Mid-delay-click"
                                  />
                                </div>
                              </div>

                              <div
                                style={{
                                  border: "1px solid var(--border-50)",
                                  padding: "10px 30px 0px 5px ",
                                  fontSize: "0.75vw",
                                  fontWeight: "500",
                                  color: "var(--font-600)",
                                  width: "8vw",
                                  height: "4.5vh",
                                }}
                              >
                                <span>Minutes</span>
                              </div>
                            </div>
                          </div>
                          <div className="flex-row align-center">
                            <img src={ICONS?.MaximumIcon} alt="" />

                            <span className="delay-minutes pl-5">
                              Delay should be greater than 5 minutes and less
                              than 20 minutes
                            </span>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              );
            })}
          </>
        )}
    </>
  );
};

export default Individual;

import React, { useEffect, useMemo, useState } from "react";
import "../../../../channels/styles/templateList.css";

import dayjs from "dayjs";
import Tick from "../../../../../assets/customSVG/Tick";
import { getRandomColorsBassedOnLetters } from "helper/colors";
import { useNavigate } from "react-router-dom";
import { contactSourceIcons } from "../../../../../constant/app/crm/crm";
import { Tooltip } from "antd";
import Label from "../../commonComponents/label";
import { hexToRgba } from "../../../helpers";
import Tags from "./Tags";
import AddCircle from "../../../../../assets/customSVG/AddCircle";
import { ICONS } from "../../../../../assets/icons";
import { setOriginApi, setOrigins } from "../../../../../reduxToolkit/crmSlice";
import { useAspDispatch } from "../../../../../test/jest-redux-hooks";
import { contactOriginApi } from "../../../api/Api";

export default function CrmTable({ data, visibleColumns, isFilter }) {
  const tdStyle = " pr-[0.5vw] !text-[0.85vw] !font-[500] text-[#616874]";
  const dispatch = useAspDispatch();

  const truncateName = (name, length) => {
    return name.length > length ? `${name.substring(0, length)}...` : name;
  };

  const firstName = truncateName(data?.first_name || "", 15);
  const lastName = truncateName(data?.last_name || "", 15);
  const fullName = `${data?.first_name || ""} ${data?.last_name || ""}`;
  const name = `${firstName} ${lastName}`;
  const email1 = data?.person_contacts?.find(
    (item) =>
      item?.priority === 1 && item?.contact_type_name === "Email address"
  )?.email;

  const crmFontColor = "#616874";

  const navigate = useNavigate();
  const firstLetter = firstName?.slice(0, 1).toUpperCase() || "A";
  const colors = getRandomColorsBassedOnLetters(firstLetter);

  const handleNavigate = (id) => {
    const colors = getRandomColorsBassedOnLetters(firstLetter);
    navigate(`/user/crm/${id}`, { state: { colors } });
    // navigate(`/user/crm/${id}`);
  };

  const contactSource = data?.contact_origin || "--";
  const contactIcon = contactSourceIcons[contactSource];

  const maxVisibleTags = 2;

  const visibleTags = data?.tags?.slice(0, maxVisibleTags);
  const additionalTagCount = data?.tags?.length - maxVisibleTags;

  return (
    <>
      {name?.toLowerCase()?.includes(isFilter?.toLowerCase()) && (
        <tr
          className={`border-[0.8vw] !rounded-[0.4vw] overflow-hidden group/container pointer`}
          style={{
            height: "2vh !important",
          }}
        >
          <td
            className="pl-10 z-50"
            onClick={() => handleNavigate(data?.id)}
            data-testid="Edit-contact"
          >
            <div className="crm-table-list w-full max-w-[250px] h-[1.6vw]">
              {/* <label
                className="checkBoxContainer"
                style={{ marginTop: "3.1px" }}
              >
                <input type="checkbox" name="checkbox" />
              </label> */}
              <div className="flex-row align-center">
                <div
                  className="size-10 min-w-10 border-2 border-white rounded-full text-[var(--font-600)] text-base weight-semibold flex items-center capitalize justify-center bg-gray-100"
                  style={{
                    color: colors.color,
                    backgroundColor: colors.backgroundColor,
                  }}
                >
                  {firstLetter}
                </div>
                <div className="flex flex-1 text-left z-50 pl-10">
                  {name?.length > 20 ? (
                    <Tooltip title={fullName}>
                      <h4
                        className={`w-1/2 text-[0.85vw] font-[500] capitalize text-[#616874] truncate`}
                      >
                        {fullName}
                      </h4>
                    </Tooltip>
                  ) : (
                    <h4
                      className={`text-[0.85vw] font-[500] capitalize text-[#616874]`}
                    >
                      {name}
                    </h4>
                  )}
                </div>
              </div>
            </div>
          </td>

          <td
            className={`${tdStyle} w-full h-[3.5vw] flex items-center justify-center !text-[0.85vw]`}
          >
            {/* {data?.person_contacts?.map((item) =>
              item?.priority === 1 && item?.contact_type === "MOBILE_PHONE" ? (
                <>
                  {item?.phone_country_code && (
                    <span className="pr-5">(+{item.phone_country_code})</span>
                  )}
                  {item?.phone_number || "--"}
                </>
              ) : (
                ""
              )
            )} */}
            {data?.person_contacts?.filter(
              (item) => item?.priority === 1 && item?.phone_number !== null
            ).length > 0
              ? data.person_contacts.map((item) =>
                  item?.priority === 1 && item?.phone_number !== null ? (
                    <React.Fragment key={item?.id}>
                      {item?.phone_country_code && (
                        <span className="pr-5">
                          ({item?.phone_country_code})
                        </span>
                      )}
                      {item?.phone_number}
                    </React.Fragment>
                  ) : null
                )
              : "--"}
          </td>
          <td className={`${tdStyle} max-w-[1vw]`}>
            {email1?.length > 25 ? (
              <Tooltip title={email1}>
                <div className="w-full text-[0.85vw] truncate overflow-hidden whitespace-nowrap pb-1">
                  {email1 || "--"}
                </div>
              </Tooltip>
            ) : (
              <div className="w-full text-[0.85vw] truncate overflow-hidden whitespace-nowrap pb-1">
                {email1 || "--"}
              </div>
            )}
          </td>

          <td className={`${tdStyle}`}>
            {contactIcon ? (
              <div className="flex-row gap-2 pl-16 ml-5">
                <img
                  src={contactIcon}
                  alt={contactSource}
                  className="w-[22px]"
                />
                {contactSource}
              </div>
            ) : (
              "--"
            )}
          </td>
          {/* {visibleColumns?.includes("Life Cycle Stage") && (
            <td className={`${tdStyle}`}>{data?.life_cycle_stage || "--"}</td>
          )} */}
          <td className={`${tdStyle}`}>
            {data?.created_at
              ? dayjs(data?.created_at).format("ddd MMM DD YYYY")
              : "--"}
          </td>
          {visibleColumns?.includes("Country") && (
            <td className={`${tdStyle}`}>{data?.country || "--"}</td>
          )}
          {visibleColumns?.includes("State") && (
            <td className={`${tdStyle}`}>{data?.province || "--"}</td>
          )}
          {visibleColumns?.includes("City") && (
            <td className={`${tdStyle}`}>{data?.city || "--"}</td>
          )}
          {visibleColumns?.includes("Tags") && (
            <td className={`${tdStyle}`}>
              <div className="flex-row align-center justify-center gap-2">
                {Array.isArray(visibleTags) && visibleTags?.length > 0 ? (
                  visibleTags?.map((tag) => {
                    return (
                      <Label
                        id={tag?.id}
                        key={tag?.id}
                        label={tag?.tag}
                        color={tag?.display_color}
                        style={{
                          backgroundColor: hexToRgba(tag?.display_color, 0.2),
                        }}
                        mainClassNames="w-40"
                      />
                    );
                  })
                ) : (
                  <span></span>
                )}
                {additionalTagCount > 0 && (
                  <span className="text-[0.85vw] text-[var(--font-400)] bg-[var(--channelButton)] rounded-full p-1">{`+${additionalTagCount}`}</span>
                )}
                <p className="flex text-[#616874] text-[0.85vw] items-center font-[500] gap-[.4vw] cursor-pointer disabled-row">
                  {/* <AddCircle width="1vw" strokeWidth="2.5" stroke="#616874" /> */}
                  <img src={ICONS?.addTags} alt="addTags" />
                </p>
                {/* <Tags
                // tagList={tagList}
                // addAction={addAction}
                zIndex={0}
                // closeAction={closeAction}
                // setTags={setTags}
                Button={
                  <p className="flex text-[#616874] text-[0.85vw] items-center font-[500] gap-[.4vw] cursor-pointer disabled-row">
                    <AddCircle width="1vw" strokeWidth="2.5" stroke="#616874" />
                  </p>
                }
              /> */}
              </div>
            </td>
          )}
          {visibleColumns?.includes("Last Contacted") && (
            <td className={`${tdStyle} text-[0.85vw]`}>
              {/* {dayjs(data?.last_contacted_at).format("ddd MMM DD YYYY") || "--"} */}
              {data?.last_contacted_at
                ? dayjs(data?.last_contacted_at).format("ddd MMM DD YYYY")
                : "--"}
            </td>
          )}
          {visibleColumns?.includes("Alternative Mobile") && (
            <td
              className={`${tdStyle} w-full h-[3.5vw] flex items-center justify-center`}
            >
              {data?.person_contacts?.filter(
                (item) => item?.priority === 2 && item?.phone_number !== null
              ).length > 0
                ? data.person_contacts.map((item) =>
                    item?.priority === 2 && item?.phone_number !== null ? (
                      <React.Fragment key={item?.id}>
                        {item?.phone_country_code && (
                          <span className="pr-5">
                            (+{item?.phone_country_code})
                          </span>
                        )}
                        {item?.phone_number}
                      </React.Fragment>
                    ) : null
                  )
                : "--"}
            </td>
          )}
          {visibleColumns?.includes("First Name") && (
            <>
              {firstName?.length > 15 ? (
                <Tooltip title={firstName}>
                  <td className={`${tdStyle} capitalize`}>
                    {firstName || "--"}
                  </td>
                </Tooltip>
              ) : (
                <td className={`${tdStyle} capitalize`}>{firstName || "--"}</td>
              )}
            </>
          )}
          {visibleColumns?.includes("Last Name") && (
            <>
              {lastName?.length > 15 ? (
                <Tooltip title={lastName}>
                  <td className={`${tdStyle} capitalize`}>
                    {lastName || "--"}
                  </td>
                </Tooltip>
              ) : (
                <td className={`${tdStyle} capitalize`}>{lastName || "--"}</td>
              )}
            </>
          )}
          {visibleColumns?.includes("Whatsapp Opt Out") && (
            <td className={`${tdStyle}`}>
              {data?.opted_out?.whatsapp ? (
                <td className={`${tdStyle}`}>
                  <Tick
                    stroke={crmFontColor}
                    width="15vw"
                    height="10"
                    strokeWidth="3"
                  />
                </td>
              ) : (
                "--"
              )}
            </td>
          )}
          {visibleColumns?.includes("Email Opt Out") && (
            <td className={`${tdStyle}`}>
              {data?.opted_out?.email ? (
                <td className={`${tdStyle}`}>
                  <Tick
                    stroke={crmFontColor}
                    width="15vw"
                    height="10"
                    strokeWidth="3"
                  />
                </td>
              ) : (
                "--"
              )}
            </td>
          )}
          {visibleColumns?.includes("SMS Opt Out") && (
            <td className={`${tdStyle}`}>
              {data?.opted_out?.sms ? (
                <td className={`${tdStyle}`}>
                  <Tick
                    stroke={crmFontColor}
                    width="15vw"
                    height="10"
                    strokeWidth="3"
                  />
                </td>
              ) : (
                "--"
              )}
            </td>
          )}
        </tr>
      )}
    </>
  );
}
